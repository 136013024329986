const toastWrappers = document.querySelectorAll(".q-toast");

const isNotEmpty = toastWrappers.length > 0;

if(isNotEmpty) {
  toastWrappers.forEach((toastWrapper) => {
    const close = toastWrapper.querySelector(".close-message");

    close.addEventListener("click", () => {
      toastWrapper.classList.add("hide");
    });
  });
}
